import { createUTCDate } from "../../utils/date";
import {
  LIST_CAMPS,
  UPDATE_CAMP,
  SET_CAMP,
  SET_CAMPS,
  SAVE_CAMP,
  REMOVE_CAMP,
} from "../actions/camps/types";

const initialState = {
  isFetching: false,
  camps: [],
  campDetail: {},
};

const camps = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CAMPS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        camps: action.camps || state.camps,
      };
    case REMOVE_CAMP:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_CAMP:
      return {
        ...state,
        campDetail: {
          ...state.campDetail,
          [action.field]: action.value,
          dateFromUTC:
            action.field === "dateFrom" &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.campDetail.dateFromUTC,
          dateToUTC:
            action.field === "dateTo" &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.campDetail.dateToUTC,
        },
      };
    case SET_CAMP:
      return {
        ...state,
        isFetching: false,
        campDetail: action.campDetail,
      };
    case SET_CAMPS:
      return {
        ...state,
        isFetching: false,
        camps: action.camps,
      };
    case SAVE_CAMP:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default camps;
