import { createUTCDate } from "../../utils/date";
import {
  LIST_RENTALS,
  UPDATE_RENTAL,
  SET_RENTAL,
  SET_RENTALS,
  SAVE_RENTAL,
  REMOVE_RENTAL,
} from "../actions/rentals/types";

const initialState = {
  isFetching: false,
  rentals: [],
  rentalDetail: {},
};

const rentals = (state = initialState, action) => {
  switch (action.type) {
    case LIST_RENTALS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        rentals: action.rentals || state.rentals,
      };
    case REMOVE_RENTAL:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_RENTAL:
      return {
        ...state,
        rentalDetail: {
          ...state.rentalDetail,
          [action.field]: action.value,
          dateFromUTC:
            action.field === "dateFrom" &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.rentalDetail.dateFromUTC,
          dateToUTC:
            action.field === "dateTo" &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.rentalDetail.dateToUTC,
        },
      };
    case SET_RENTAL:
      return {
        ...state,
        isFetching: false,
        rentalDetail: action.rentalDetail,
      };
    case SET_RENTALS:
      return {
        ...state,
        isFetching: false,
        rentals: action.rentals,
      };
    case SAVE_RENTAL:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default rentals;
