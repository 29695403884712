import { combineReducers } from "redux";
import app from "./app";
import auth from "./auth";
import customers from "./customers";
import deals from "./deals";
import users from "./users";
import content from "./content";
import camps from "./camps";
import rentals from "./rentals";
import brands from "./brands";
import categories from "./categories";
import productFeatures from "./productFeatures";
import products from "./products";
const appReducer = combineReducers({
  app,
  auth,
  customers,
  deals,
  users,
  content,
  camps,
  rentals,
  brands,
  categories,
  productFeatures,
  products,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    const { app, layout } = state;
    state = { app, layout };
  }
  return appReducer(state, action);
};

export default rootReducer;
