import {
  faUsersCog,
  faShieldAlt,
  faUsers,
  faBox,
  faStream,
  faFileImport,
  faDollarSign,
  faGripLines,
  faLayerGroup,
  faBoxes,
  faHome,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

const NavigationLinks = [
  {
    icon: faUsers,
    color: "#f1cc05",
    title: "Gebruikers",
    link: "/users",
  },
  {
    icon: faShieldAlt,
    color: "#4ba989",
    title: "Merken",
    link: "/brands",
  },
  {
    icon: faStream,
    color: "#f10550",
    title: "Categorieën",
    link: "/categories",
  },
  {
    icon: faBox,
    color: "#f17305",
    title: "Producten",
    link: "/products",
  },
  {
    icon: faStar,
    color: "#e3d64b",
    title: "Product Features",
    link: "/productfeatures",
  },
  {
    icon: faFileImport,
    color: "#05f17f",
    title: "Importeren",
    link: "/import",
  },
  {
    icon: faDollarSign,
    color: "#059ef1",
    title: "Bestellingen",
    link: "/orders",
  },
  {
    icon: faGripLines,
    color: "#e84ddb",
    title: "Content",
    link: "/content",
  },
  {
    icon: faLayerGroup,
    color: "#e84ddb",
    title: "Contentgroepen",
    link: "/contentgroups",
  },
  {
    icon: faUsersCog,
    color: "#dd7e5e",
    title: "Klanten",
    link: "/customers",
  },
];

export default NavigationLinks;
