import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const CustomSnackbar = (props) => {
  const { message, variant, delay, onClose } = props;
  let snackTimeout = undefined;
  snackTimeout = setTimeout(() => {
    clearTimeout(snackTimeout);
    snackTimeout = undefined;
    onClose();
  }, delay || 5000);
  return (
    <div
      className={`snackbar-root ${variant} ${message ? "showing" : "hidden"}`}
    >
      <p>{message}</p>
    </div>
  );
};

CustomSnackbar.propTypes = {
  variant: PropTypes.string,
  message: PropTypes.string,
  messageType: PropTypes.string,
  action: PropTypes.string,
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default CustomSnackbar;
